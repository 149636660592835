import React from "react";
import { graphql } from "gatsby";
import { Container } from "reactstrap";
import { Form, Field, FormSpy } from "react-final-form";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import Layout from "../../../layouts/index";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/product/banner.jpg";
import SvgIconsSet from "../../../components/SvgIconsSet";
import { sendEmail } from "../../../services/contact";
import { generateDownload } from "../../../utils/helperFunctions";

// Images
import BannerCloud from "../../../images/services/product/banner-cloud.png";
import BannerFullstack from "../../../images/services/product/banner-fullstack.jpg";
import BannerAi from "../../../images/services/product/banner-ai.png";
import BannerIot from "../../../images/services/product/banner-iot.jpg";

// Process
import OurProcess from "../../../components/services/OurProcess";

// Casestudy
import downloadFile from "../../../documents/Case-Study-Product-Development.pdf";
import downloadFile2 from "../../../documents/Case-Study-Product-Development-Stride.pdf";
import caseStudyImgOne from "../../../images/A1-Casestudy.png";
import caseStudyImgTwo from "../../../images/Web-app.png";
import CasestudyTwo from "../../../components/CasestudyTwo";

// Contact
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import BookConsultation from "../../../components/BookConsultation";
import ConsultationBanner from "../../../images/consultation.jpg";

const ProductDevelopment = () => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field"),
  });

  const validate = (values) => {
    const errors = {};
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
    }
    return errors;
  };

  const onSubmitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    generateDownload(document, [downloadFile, downloadFile2]);

    const formData = { ...values };
    formData.Message =
      "\nCASE STUDY 1: HOW UPGRADING LEGACY SYSTEMS CREATED A NEW INCOME STREAM FOR A1 SPARE PARTS\nCASE STUDY 2: HOW A WEB APPLICATION REVAMP HELPED A CROWDFUNDING PLATFORM STAND OUT AMONGST COMPETITORS";
    formData.FormType = "CaseStudy";
    formData.Subject = "CASE STUDY: PRODUCT DEVELOPMENT";

    try {
      await sendEmail(formData);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Layout bodyClass="page-services prod-dev">
      <Seo
        keyword="Product Development Services"
        title="Product Development"
        description="Our team offers a complete range of cloud engineering & DevOps, full stack development, AI & ML capabilities and IoT & device development. Contact us today to find out more."
      />
      <IntroBanner
        title="Product Development"
        subtitle="We take you from ideation to delivery and beyond"
        image={Banner}
      />
      <div className="container pb-6 section-2">
        <div className="row pt-6 mb-3">
          <div className="col-12">
            <h2 className="title-1">Our Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-5">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerCloud}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Cloud Engineering &amp; DevOps</h2>
            <p>
              <strong>Streamline your cloud investment</strong>
              <br />
              We offer a range of cloud infrastructure and integration services
              to automate, streamline and enhance operations. We optimise your
              cloud investment to deliver improved efficiency and reduced costs.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerFullstack}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Full Stack Development</h2>
            <p>
              <strong>Create a cohesive and fully integrated solution</strong>
              <br />
              We offer both front-end and back-end development teams that work
              together seamlessly to deliver a fully functional and
              cost-effective solution.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerAi}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">AI Capabilities</h2>
            <p>
              <strong>Leverage AI in your existing systems</strong>
              <br />
              We design and apply AI models from initial design (modelling),
              through its learning phase (training), to its practical
              application (consuming). Discover new business insights, increase
              efficiency of complex processes, drive innovation and gain a
              competitive advantage.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerIot}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">IoT &amp; Device Development</h2>
            <p>
              <strong>
                Enhance Business Intelligence & Operational efficiency
              </strong>
              <br />
              Our team are experts in the sophisticated engineering of
              interconnecting digital devices and systems via the internet to
              increase process efficiency, customer experience and enhance
              business intelligence.
            </p>
          </div>
        </div>
        <div className="getting-touch">
          <GetInTouchbtn textdce="Free consultation" />
        </div>
      </div>

      <Container>
        <OurProcess />
      </Container>

      <section>
        <CasestudyTwo
          image1={caseStudyImgOne}
          title1="How upgrading legacy systems created a new income stream for A1 Spare Parts"
          text1="When A1 set out to tackle legacy system inefficiencies, the resulting solution created a whole new income stream that has future-proofed the business."
          download1={null}
          image2={caseStudyImgTwo}
          title2="How a web application revamp helped a crowdfunding platform stand out amongst competitors"
          text2="iTelaSoft's support and expertise has yielded significant enhancements to the client's web application, resulting in a markedly improved user experience."
          download2={null}
        />
      </section>

      <section className="pt-0">
        <div className="section-padding wrap-contrast-section download-portfolio consultation wrap-case-study">
          <Container>
            <div className="contrast-section">
              <div className="">
                <div className="">
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="flex-column">
                        <div className="form-segment pb-3 mx-1">
                          <p>
                            <b>Download the Case Studies Now!</b>
                          </p>
                          <Form
                            onSubmit={onSubmitHandler}
                            validate={validate}
                            render={({ handleSubmit }) => (
                              <form
                                className="case-study-email-form d-flex flex-sm-row flex-column mb-1"
                                onSubmit={handleSubmit}
                              >
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="email-area col-lg-6 col-md-6 col-sm-6 col-12 pr-1 pl-0">
                                      <input
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...input}
                                        type="email"
                                        placeholder="Enter your e-mail address"
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="error-val">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <FormSpy
                                  subscription={{
                                    hasValidationErrors: true,
                                    submitting: true,
                                  }}
                                >
                                  {(subscriptions) => (
                                    <Button
                                      className={`btn-icon-button mt-1 mt-sm-0 mb-2 ${
                                        subscriptions.hasValidationErrors ||
                                        subscriptions.submitting
                                          ? "disabled-button"
                                          : ""
                                      }`}
                                      variant="primary"
                                      type="submit"
                                    >
                                      <span className="btn-text">
                                        Download Case Studies
                                      </span>
                                      <span className="btn-icon">
                                        <SvgIconsSet.SideArrow />
                                      </span>
                                    </Button>
                                  )}
                                </FormSpy>
                              </form>
                            )}
                          />
                          <p className="small-text">
                            By downloading these case studies, you agree to
                            receive emails from iTelaSoft about products,
                            services, and updates. You can unsubscribe at any
                            time.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <Container className="my-6">
        <h2 className="title-1 mb-3">Book a free consultation</h2>
        <BookConsultation image={ConsultationBanner} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "cloud-services-1024x597.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default ProductDevelopment;
